import React, { useState } from "react";
import { navlinks } from "../constants";
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
  const [menu, setMenu] = useState(1);

  return (
    <div
      className={`flex justify-between items-center bg-gray-800 px-8 h-[10vh] w-screen text-gray-100 font-nav fixed top-0 left-0 z-50 transition-opacity duration-300}`}
    >
      {/* Navbar left */}
      <a href="#home" className="text-yellow-300 hover:font">
        subedi.js
      </a>
      {/* Navbar right */}
      <ul className="lg:w-[40%] md:w-[55%] sm:w-[65%] hidden sm:flex justify-between">
        {navlinks.map((items, index) => (
          <li
            key={index}
            className="transform transition-transform duration-200 hover:scale-125"
          >
            <a href={items.href} className="active:text-yellow-700">
              {items.name}
            </a>
          </li>
        ))}
      </ul>

      {/* Menu Buttons */}
      <div className="sm:hidden flex absolute right-8 z-10">
        {menu ? (
          <IoMenu size={20} onClick={() => setMenu(!menu)} />
        ) : (
          <IoClose size={20} onClick={() => setMenu(!menu)} />
        )}
      </div>

      {/* Responsive Menu  */}
      {!menu && (
        <div
          className={`h-[100vh] bg-gray-500 w-[40%] fixed right-0 top-0 flex justify-center items-center sm:hidden opacity-85`}
        >
          <ul className="h-[50%] flex text-center gap-20 flex-col">
            {navlinks.map((items, index) => (
              <li
                key={index}
                className="transform transition-transform duration-200 hover:scale-125 text-gray-200 font-bold"
              >
                <a href={items.href} onClick={() => setMenu(!menu)}>
                  {items.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
