export const navlinks = [
  { name: "Home", href: "#home" },
  { name: "About", href: "#about" },
  { name: "Projects", href: "#projects" },
  { name: "Contact", href: "#contact" },
];

export const projects = [
  {
    id: "gpf",
    name: "Github Profile Finder",
    desc: "Finding the Github information about the user by giving the username into the input field.",
    photo: "",
    repo: "https://github.com/subigya-js/GitHub-Profile-Finder",
    deploy: "https://github-profilee-finderr.netlify.app/",
  },

  {
    id: "wa",
    name: "Weather Application",
    desc: "Getting the Weather Update of Particular City according to the User's input.",
    photo: "",
    repo: "https://github.com/subigya-js/React-Weather-App/tree/main/weather-app",
    deploy: "https://weather-app-subedi.netlify.app/",
  },

  {
    id: "tdl",
    name: "ToDo Application",
    desc: "A regular ToDo Applications to add and remove daily activities.",
    photo: "",
    repo: "https://github.com/subigya-js/ToDo-List",
    deploy: "https://todo-list-subedi.netlify.app/",
  },

  {
    id: "ct",
    name: "Crypto Tracker",
    desc: "A CryptoTracker web application to track individual Crypto Currencies. ",
    photo: "",
    repo: "https://github.com/subigya-js/Cypto-Tracker",
    deploy: "https://crypto-tracker-subigya.netlify.app/",
  },

  {
    id: "cx",
    name: "Currency Exchange",
    desc: "A Web Application to find the exchange rate of any currency in the world. ",
    photo: "",
    repo: "/",
    deploy: "/",
  },

  {
    id: "vcr",
    name: "Virtual Classroom",
    desc: "A Full Stack Application to handle all the Classroom activities including Live Online Classes, Assignment Submission, Notes Sharing and Attendance Collection.",
    photo: "",
    repo: "/",
    deploy: "/",
  },
];
