import React from "react";
import js from "../assets/js.png";
import react from "../assets/react.webp";
import java from "../assets/java.webp";
import nodejs from "../assets/nodejs.png";
import mongodb from "../assets/mongodb.png";
import python from "../assets/python.png";
const About = () => {
  return (
    <>
      <div
        className="min-h-[400px] h-auto bg-cyan-950 w-screen md:flex justify-between"
        id="about"
      >
        {/* About left  */}
        <div className="md:w-[50%] flex pt-20 flex-col justify-center">
          <h1 className="text-yellow-300 lg:text-5xl pl-8 text-4xl">whoami?</h1>

          <p className="text-gray-200 w-[90%] text-justify pl-8 mt-10 text-xl">
            I am a Computer Science Engineering Undergraduate and a Full Stack Web Application
            Developer, learning and building things since 3 years.
          </p>
        </div>

        {/* About right  */}
        <div className="md:w-[50%] grid grid-rows-3 md:grid-rows-2 py-24 md:mt-20 grid-flow-col gap-32 md:gap-16 xl:gap-32 place-content-center p-4">
          <img
            src={js}
            alt="js"
            className="lg:h-[80px] md:h-[60px] h-[70px] rounded-3xl hover:scale-125 duration-300"
          />
          <img
            src={react}
            alt="react"
            className="lg:h-[80px] md:h-[60px] h-[70px] rounded-lg hover:scale-125 duration-300"
          />
          <img
            src={java}
            alt="angular"
            className="lg:h-[90px] md:h-[80px] h-[90px] rounded-lg hover:scale-125 duration-300"
          />
          <img
            src={nodejs}
            alt="nodejs"
            className="lg:h-[80px] md:h-[60px] h-[70px] rounded-lg hover:scale-125 duration-300"
          />
          <img
            src={mongodb}
            alt="mongodb"
            className="lg:h-[80px] md:h-[60px] h-[70px] rounded-lg hover:scale-125 duration-300"
          />
          <img
            src={python}
            alt="python"
            className="lg:h-[80px] md:h-[60px] h-[70px] rounded-lg hover:scale-125 duration-300"
          />
        </div>
      </div>
    </>
  );
};

export default About;
