import React, { useRef, useState } from "react";
import { LuSendHorizonal } from "react-icons/lu";
import { FaFacebook, FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa6";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const sendEmail = (event) => {
    event.preventDefault();

    if (!formValues.name || !formValues.email || !formValues.message) {
      alert("Please complete the input field");
      return;
    }

    emailjs
      .sendForm(
        "service_0f7jqzc",
        "template_s3dbepq",
        form.current,
        "r2LJxizAL64rZzdGG"
      )
      .then(
        () => {
          alert("Email Sent");

          setFormValues({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          alert(`${error.text}`);
        }
      );
  };

  return (
    <div
      className="min-h-[500px] h-auto bg-cyan-950 w-screen font-body pt-20 pb-12 px-8"
      id="contact"
    >
      <h1 className="text-yellow-300 lg:text-5xl text-4xl min-h-">
        Contact Me
      </h1>

      {/* Form */}
      <div className="w-screen flex items-center mt-4">
        <form
          ref={form}
          onSubmit={sendEmail}
          className="h-[550px] xl:w-[90%] w-[80%] border-yellow-300 p-5 flex flex-col gap-6"
        >
          <div className="flex flex-col gap-3">
            <label className="text-blue-300">
              Full Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              value={formValues.name}
              onChange={handleChange}
              name="name"
              type="text"
              className="xl:w-[40%] p-2 outline-none rounded-md"
              placeholder="Your Name"
            />
          </div>

          <div className="flex flex-col gap-3">
            <label className="text-blue-300">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              required
              value={formValues.email}
              onChange={handleChange}
              name="email"
              type="email"
              className="xl:w-[40%] p-2 outline-none rounded-md"
              placeholder="Your Email"
            />
          </div>

          <div className="flex flex-col gap-3">
            <label className="text-blue-300">
              Message <span className="text-red-500">*</span>
            </label>
            <textarea
              required
              value={formValues.message}
              onChange={handleChange}
              name="message"
              type="text"
              className="xl:w-[40%] p-2 outline-none rounded-md min-h-[220px] max-h-[220px] text-justify"
              placeholder="Your Message"
            />
          </div>

          <div>
            <button
              className="bg-yellow-300 px-4 py-2 rounded-md w-auto flex items-center gap-2 hover:bg-blue-300 duration-300"
              type="submit"
            >
              Send <LuSendHorizonal className="hover:-rotate-45 duration-200" />
            </button>
          </div>
        </form>
      </div>

      {/* Links */}
      <div className="mt-12 px-5">
        <h1 className="text-blue-200 text-2xl">Let's get connected...</h1>
        <ul className="flex gap-10 mt-5 items-center">
          <li className="text-2xl cursor-pointer text-blue-200 hover:scale-125 duration-300">
            <a href="https://www.facebook.com/subigya.jsx" target="__blank">
              <FaFacebook />
            </a>
          </li>
          <li className="text-2xl cursor-pointer text-red-200 hover:scale-125 duration-300">
            <a href="https://www.instagram.com/subedi.js/" target="__blank">
              <FaInstagram />
            </a>
          </li>
          <li className="text-2xl cursor-pointer text-blue-300 hover:scale-125 duration-300">
            <a href="https://www.linkedin.com/in/subigya-js/" target="__blank">
              <FaLinkedin />
            </a>
          </li>
          <li className="text-2xl cursor-pointer text-white hover:scale-125 duration-300">
            <a href="https://github.com/subigya-js" target="__blank">
              <FaGithub />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
