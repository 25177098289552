import React from "react";
import HeroImage from "../assets/Gaurav.jpeg";

const Home = () => {
  return (
    <div
      className="flex flex-col justify-around items-center p-10 bg-cyan-950 min-h-[80vh] overflow-hidden w-screen md:flex-row mt-[10vh] font-body"
      id="home"
    >
      {/* Home Left */}
      <div className="flex flex-col gap-5 md:w-auto lg:w-[50%]">
        <h1 className="text-yellow-400 lg:text-5xl md:text-3xl text-3xl">
          SUBIGYA SUBEDI
        </h1>
        <p className="text-blue-300 text-xs lg:text-xl">
          A Full Stack Web Aplication Developer
        </p>
      </div>

      {/* Home Right  */}
      <div className="">
        <img
          src={HeroImage}
          alt="HeroImage"
          className="h-[230px] w-[230px] lg:h-[350px] lg:w-[350px] rounded-full border-4 border-yellow-400 hover:scale-125 duration-300"
        />
      </div>
    </div>
  );
};

export default Home;
