import React from "react";
import { FaArrowCircleUp } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="bg-blue-200 w-screen pl-8 font-nav lg:pr-24 pr-12 py-4 flex justify-between items-center">
        <h1>&#169; SUBIGYA SUBEDI</h1>

        <h1 className="flex items-center gap-3">
          v2.1{" "}
          <span>
            <a href="/">
              <FaArrowCircleUp className="text-gray-900 text-xl cursor-pointer hover:scale-125 duration-300" />
            </a>
          </span>
        </h1>
      </div>
    </>
  );
};

export default Footer;
