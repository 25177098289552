import React from "react";
import { projects } from "../constants";

const Projects = () => {
  return (
    <>
      <div
        className="min-h-[500px] h-auto bg-cyan-950 w-screen font-body py-44"
        id="projects"
      >
        <h1 className="text-yellow-300 lg:text-5xl px-8 text-4xl">
          My Projects
        </h1>

        {/* Cards */}
        <div className="min-h-[600px] h-auto w-screen p-8 grid gap-20 grid-cols-1 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
          {projects.map((items) => (
            <div
              key={items.id}
              className="border border-yellow-300 min-h-[300px] min-w-[200px] rounded-lg hover:scale-105 cursor-pointer duration-300 flex flex-col justify-between"
            >
              <h1 className="bg-yellow-300 text-center text-md font-semibold p-3 rounded-t-lg text-gray-950">
                {items.name}
              </h1>

              <div className="w-full flex justify-center items-center">
                <h1 className="text-gray-100 px-3 text-justify max-w-[90%] bg-green-00">
                  {items.desc}
                </h1>
              </div>

              {/* Links */}
              <div className="">
                {items.repo && items.deploy === "/" ? (
                  <div className="bg-yellow-300 mb-5 px-4 py-2 text-center h-full rounded-b-md flex justify-center items-center hover:bg-blue-300 duration-200">
                    Ongoing Project
                  </div>
                ) : (
                  <div className="flex justify-between px-8">
                    <a
                      href={items.repo}
                      target="__blank"
                      className="bg-yellow-300 mb-5 px-4 py-2 rounded-md hover:bg-blue-300 duration-200"
                    >
                      Repo
                    </a>
                    <a
                      href={items.deploy}
                      target="__blank"
                      className="bg-yellow-300 mb-5 px-4 py-2 rounded-md hover:bg-blue-300 duration-200"
                    >
                      Deploy
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;
